<template>
  <v-card class="ma-4">
    <v-toolbar>
      <v-toolbar-title>Chapel Schedule</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom v-if="isAdmin">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="addChapel">
            <v-icon>fal fa-plus</v-icon>
          </v-btn>
        </template>
        <span>Add Chapel</span>
      </v-tooltip>
      <schedule-setup v-if="isAdmin" @updated="loadTerms"></schedule-setup>
    </v-toolbar>
    <v-tabs v-model="selectedTab">
      <v-tab>List View</v-tab>
      <v-tab>Calendar View</v-tab>
    </v-tabs>
    <v-tabs-items v-model="selectedTab">
      <v-tab-item>
        <chapel-table ref="chapelTableRef" @edit="editChapel" @remove="removeChapel" @view="viewChapel"></chapel-table>
      </v-tab-item>
      <v-tab-item>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-btn icon @click="prevMonth()" :disabled="currentMonth <= 7 && currentYear === 2012">
            <v-icon>fal fa-chevron-left</v-icon>
          </v-btn>
          <div style="padding:0 .5em">
            <v-select v-model="currentMonth" :items="monthOptions" label="Month" hide-details outlined dense style="max-width:150px"></v-select>
          </div>
          <div style="padding:0 .5em">
            <v-select v-model="currentYear" :items="yearOptions" label="Year" hide-details outlined dense style="max-width:150px"></v-select>
          </div>
          <v-btn icon @click="nextMonth()">
            <v-icon>fal fa-chevron-right</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-sheet height="650" id="chapelCalendar">
          <v-calendar ref="calendar" :events="eventsForCalendar" type="month" event-overlap-mode="stack" @change="loadChapels" @click:event="showEvent" @click:date="dateClicked" :start="currentDate" :event-color="getEventColor"></v-calendar>
        </v-sheet>
      </v-tab-item>
    </v-tabs-items>
    <chapel-edit v-if="isAdmin" :chapel="selectedChapel.chapel" v-model="selectedOpen" @updated="selectedTab === 0 ? chapelTableRef.load() : loadChapels()"></chapel-edit>
    <v-menu v-else v-model="selectedOpen" :close-on-content-click="false" :activator="selectedElement" offset-x>
      <v-card color="grey lighten-4" min-width="350px" flat>
        <v-toolbar :color="selectedChapel.color" dark>
          <v-toolbar-title>{{ selectedChapel.name }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <div v-if="selectedChapel.chapel">Speaker: {{ selectedChapel.chapel.speaker || '--No Speaker Specified --' }}</div>
          <div v-if="selectedChapel.chapel">Title: {{ selectedChapel.chapel.title || '-- No Title Specified --' }}</div>
          <div v-if="selectedChapel.chapel && selectedChapel.chapel.series">Series: {{ selectedChapel.chapel.series }}</div>
          <div>Date: {{ selectedChapelDate }}</div>
          <div v-if="selectedChapel.chapel && selectedChapel.chapel.links.length > 0">
            <v-divider style="margin:.5em 0"></v-divider>
            <v-btn v-if="selectedChapel.chapel.audioLink" text color="primary" @click="openLink(selectedChapel.chapel.audioLink)">Audio Recording</v-btn>
            <v-btn v-if="selectedChapel.chapel.videoLink" text color="primary" @click="openLink(selectedChapel.chapel.videoLink)">Video Recording</v-btn>
            <v-btn v-for="(link, index) in selectedChapel.links" :key="'link-'+index" @click="openLink(link.link)">{{ link.label }}</v-btn>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="secondary" @click="selectedOpen = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </v-card>
</template>
<style>
div.v-application.theme--light #chapelCalendar div.v-calendar-weekly__week div.v-calendar-weekly__day.v-present div.v-calendar-weekly__day-label button.v-btn {
  background-color: #C3DFF8;
  color: black;
}
</style>
<script>
import { ref, computed, onBeforeMount, onBeforeUnmount } from '@vue/composition-api'
import { stringFormatDate } from '@/helpers/formatters'

export default {
  components: {
    chapelTable: () => import('@/components/chapel/chapelTable'),
    scheduleSetup: () => import('@/components/chapel/scheduleSetup'),
    chapelEdit: () => import('@/components/chapel/chapelEdit')
  },
  setup (props, { root }) {
    const isLoggedIn = computed(() => root.$store.state.loggedIn)
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const isAdmin = computed(() => 'roles' in user.value && user.value.roles.filter((role) => role === 'Technology Services' || role === 'Chapel Office').length > 0)

    const selectedTab = computed({
      get: () => root.$store.state.chapel.scheduleTab,
      set: (tab) => { root.$store.commit('chapel/setScheduleTab', tab) }
    })
    const chapels = ref([])
    const closures = ref([])
    const currentMonth = computed({
      get: () => root.$store.state.chapel.calendarMonth,
      set: (value) => { root.$store.commit('chapel/setCalendarMonth', value) }
    })
    const monthOptions = ref([{ value: 0, text: 'January' }, { value: 1, text: 'February' }, { value: 2, text: 'March' }, { value: 3, text: 'April' }, { value: 4, text: 'May' }, { value: 5, text: 'June' }, { value: 6, text: 'July' }, { value: 7, text: 'August' }, { value: 8, text: 'September' }, { value: 9, text: 'October' }, { value: 10, text: 'November' }, { value: 11, text: 'December' }])
    const currentYear = computed({
      get: () => root.$store.state.chapel.calendarYear,
      set: (value) => { root.$store.commit('chapel/setCalendarYear', value) }
    })
    const yearOptions = computed(() => {
      const arr = []
      let year = new Date().getFullYear()
      while (year > 2012) {
        arr.push(year)
        year--
      }
      return arr
    })
    const selectedChapel = ref({})
    const selectedElement = ref(null)
    const selectedOpen = ref(false)

    const currentDate = computed(() => {
      let dt = new Date(currentYear.value, currentMonth.value, 1)
      return formatDate(dt, false)
    })

    function prevMonth () {
      if (currentMonth.value === 0) {
        currentMonth.value = 11
        currentYear.value--
      } else {
        currentMonth.value--
      }
    }

    function nextMonth () {
      if (currentMonth.value === 11) {
        currentMonth.value = 0
        currentYear.value++
      } else {
        currentMonth.value++
      }
    }

    function formatDate (a, withTime) {
      return withTime
        ? `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()} ${a.getHours()}:${a.getMinutes()}`
        : `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()}`
    }
    const selectedChapelDate = computed(() => {
      if ('chapel' in selectedChapel.value && 'date' in selectedChapel.value.chapel) {
        return stringFormatDate(selectedChapel.value.chapel.date)
      }
      return ''
    })

    const service = root.$feathers.service('chapel/schedule')

    function onListener () {
      if (selectedTab.value === 1) loadChapels()
    }

    onBeforeMount(() => {
      let dt = new Date()
      if (currentYear.value === 0) {
        currentMonth.value = dt.getMonth()
        currentYear.value = dt.getFullYear()
      }

      if (root.$store.state.chapel.scheduleTermOptions.length === 0) {
        loadTerms()
      }
      service.on('created', onListener)
      service.on('updated', onListener)
      service.on('patched', onListener)
      service.on('removed', onListener)
    })

    onBeforeUnmount(() => {
      service.removeListener('created', onListener)
      service.removeListener('updated', onListener)
      service.removeListener('patched', onListener)
      service.removeListener('removed', onListener)
    })

    const eventsForCalendar = computed(() => {
      let arr = []
      for (let i = 0; i < chapels.value.length; i++) {
        let chapel = chapels.value[i]
        let end = new Date(chapel.date)
        end.setMinutes(end.getMinutes() + (end.getHours() === 11 ? 50 : 90))
        arr.push({
          name: chapel.speaker || chapel.series || '-- No Speaker Specified --',
          start: formatDate(new Date(chapel.date), true),
          end: formatDate(end, true),
          color: (end.getHours() === 11 && (end.getDay() === 1 || end.getDay() === 3 || end.getDay() === 5)) ? 'blue' : 'green',
          chapel
        })
      }
      if (closures.value.length > 0) {
        for (let i = 0; i < closures.value.length; i++) {
          arr.push(closures.value[i])
        }
      }
      return arr
    })

    function loadTerms () {
      root.$store.dispatch('chapel/loadOptions')
    }

    function loadChapels (obj) {
      chapels.value = []
      let dt = new Date()
      let start = ''
      if (obj == null) {
        dt = new Date(currentYear.value, currentMonth.value, 1)
        start = dt.toISOString().substr(0, 10)
        dt.setMonth(dt.getMonth() + 1)
      } else {
        start = obj.start.date
        dt = new Date(obj.end.date)
        dt.setDate(dt.getDate() + 1)
      }
      service.find({ query: { date: { $gte: start, $lte: dt.toISOString().substr(0, 10) }, $limit: 50 } }).catch((err) => {
        alert('Error loading chapels: ' + err)
      }).then(({ data }) => {
        chapels.value = data
      })
      root.$feathers.service('calendar/closure').find({ query: { date: { $gte: start, $lte: dt.toISOString().substr(0, 10) } } }).catch((err) => {
        alert('Error loading holidays: ' + err)
      }).then(({ data }) => {
        let events = []
        for (let i = 0; i < data.length; i++) {
          let item = data[i]
          let dt = new Date(item.date)
          let included = false
          for (let j = 0; j < events.length; j++) {
            if (item.name === events[j].name) {
              events[j].dates.push(dt.toISOString().substr(0, 10))
              included = true
            }
          }
          if (!included) {
            events.push({
              name: item.name,
              dates: [ dt.toISOString().substr(0, 10) ]
            })
          }
        }
        closures.value = []
        for (let i = 0; i < events.length; i++) {
          let min = events[i].dates[0]
          let max = events[i].dates[0]
          for (let j = 1; j < events[i].dates.length; j++) {
            if (events[i].dates[j] < min) min = events[i].dates[j]
            if (events[i].dates[j] > max) max = events[i].dates[j]
          }
          closures.value.push({
            color: 'red',
            name: events[i].name,
            start: min,
            end: max
          })
        }
      })
    }

    function getEventColor (event) {
      return event.color
    }

    function addChapel () {
      let dt = new Date()
      dt.setHours(11)
      dt.setMinutes(0)
      dt.setSeconds(0)
      dt.setMilliseconds(0)
      selectedChapel.value = {
        chapel: {
          _id: '',
          date: dt,
          speaker: '',
          title: '',
          series: ''
        }
      }
      selectedOpen.value = true
    }

    function showEvent ({ nativeEvent, event }) {
      const open = () => {
        const links = 'links' in event ? event.links.filter(({ loginRequired, visible }) => visible && (isLoggedIn.value || !loginRequired)) : []
        console.log(links)
        selectedChapel.value = { ...event, links }
        selectedElement.value = nativeEvent.target
        setTimeout(() => {
          selectedOpen.value = true
        }, 10)
      }

      if (selectedOpen.value) {
        selectedOpen.value = false
        setTimeout(open, 10)
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    }

    function viewChapel ({ chapel, target }) {
      const links = 'links' in chapel ? chapel.links.filter(({ loginRequired, visible }) => visible && (isLoggedIn.value || !loginRequired)) : []
      selectedChapel.value = {
        name: chapel.speaker || chapel.series || '-- No Speaker Specified --',
        color: 'blue',
        chapel,
        links
      }
      selectedElement.value = target
      selectedOpen.value = true
    }

    function dateClicked ({ date }) {
      if (isAdmin.value) {
        selectedChapel.value = {
          chapel: {
            _id: '',
            date: date + ' 11:00:00',
            speaker: '',
            title: '',
            series: ''
          }
        }
        selectedOpen.value = true
      }
    }

    const setupTermActive = ref(false)

    const chapelTableRef = ref(null)
    function editChapel (chapel) {
      selectedChapel.value = {
        chapel
      }
      selectedOpen.value = true
    }

    function removeChapel (chapel) {
      if (isAdmin.value && confirm('Are you sure you want to remove this chapel for ' + stringFormatDate(chapel.date) + '?')) {
        service.remove(chapel._id).catch((err) => {
          alert('Error removing chapel: ' + err)
        })
      }
    }

    function openLink (link) {
      window.open(link)
    }

    return {
      selectedTab,
      chapels,
      closures,
      currentMonth,
      monthOptions,
      currentYear,
      yearOptions,
      selectedChapel,
      selectedChapelDate,
      selectedElement,
      selectedOpen,
      currentDate,
      prevMonth,
      nextMonth,
      formatDate,
      stringFormatDate,
      eventsForCalendar,
      loadTerms,
      loadChapels,
      getEventColor,
      addChapel,
      showEvent,
      viewChapel,
      dateClicked,
      user,
      isAdmin,
      setupTermActive,
      chapelTableRef,
      editChapel,
      removeChapel,
      openLink
    }
  }
}
</script>
